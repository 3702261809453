.check_icon {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  height: 6rem;
  width: 6rem;
}
.success .lines {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  transform-origin: 50% 50%;
  animation: flare 1.5s ease-in-out forwards;
}
.success .arrow {
  transform-origin: center;
  animation: grow 1.2s ease-in-out forwards;
}
.success .blob {
  transform-origin: center;
  animation: scale 1s ease-in-out forwards;
}
.success .whiteblob {
  transform-origin: center;
  animation: whiteblobscale 1s ease-in-out forwards;
}
.success .circle {
  transform-origin: 50% 50%;
  animation: appear 1.2s ease-in-out forwards;
}
@keyframes scale {
  0% {
    transform: scale3d(0, 0, 1);
  }
  30%,
  100% {
    transform: scale3d(3.7, 3.7, 1);
  }
}
@keyframes whiteblobscale {
  0%,
  20% {
    transform: scale3d(0, 0, 1);
  }
  46%,
  100% {
    transform: scale3d(3.8, 3.8, 1);
  }
}
@keyframes appear {
  0%,
  23% {
    opacity: 0;
    transform: scale3d(0, 0, 0);
  }
  30%,
  100% {
    opacity: 1;
  }
  17% {
    transform: scale3d(1.1, 1.1, 1);
  }
  25% {
    transform: scale3d(0.8, 0.8, 1);
  }
  30% {
    transform: scale3d(1, 1, 1);
  }
  45% {
    transform: scale3d(0.9, 0.9, 1);
  }
  60% {
    transform: scale3d(1, 1, 1);
  }
  80% {
    transform: scale3d(0.95, 0.95, 1);
  }
  98% {
    transform: scale3d(1, 1, 1);
  }
}
@keyframes grow {
  0% {
    transform: scale3d(0, 0, 0);
  }
  30% {
    transform: scale3d(1.1, 1.1, 1);
  }
  45% {
    transform: scale3d(0.9, 0.9, 1);
  }
  60% {
    transform: scale3d(1, 1, 1);
  }
  80% {
    transform: scale3d(0.95, 0.95, 1);
  }
  98% {
    transform: scale3d(1, 1, 1);
  }
}
@keyframes flare {
  0%,
  10% {
    opacity: 0;
    transform: scale3d(0, 0, 0);
    stroke-dashoffset: 10;
  }
  12% {
    opacity: 1;
    transform: scale3d(0.5, 0.5, 1);
  }
  35% {
    transform: scale3d(1, 1, 1);
    stroke-dashoffset: 100;
    stroke-dasharray: 100;
  }
}
