.video-file {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.video-file .btn-select-video {
  margin-right: 1rem;
  height: 2.5em;
  font-weight: 600;
  text-align: center;
  font-size: 1em;
  background-color: rgb(0, 173, 239);
  color: rgb(255, 255, 255);
  transition: all 0.1s ease-in-out 0s;
  padding: 1px 1rem;
  border-radius: 0.1875rem;
  border-color: rgb(0, 173, 239);
  cursor: pointer;
}

.video-file input {
  display: none;
}
