.w-100 {
  width: 100%;
}
.h-full {
  height: 100%;
}
.h-100 {
  height: 100%;
}
.w-50 {
  width: 50%;
}
.p-0 {
  padding: 0px !important;
}
.pl-0 {
  padding-left: 0px !important;
}
.pr-0 {
  padding-right: 0px !important;
}
.td-n {
  text-decoration: unset;
}
.disabled {
  pointer-events: none;
}
.cp {
  cursor: pointer !important;
}
.font-bold {
  font-weight: bold !important;
}
.icon-box {
  width: 45px;
  margin-left: -44px;
  z-index: 99;
  height: 34px;
  cursor: pointer;
}
.flex-h {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-h-imp {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}
.flex-h-b {
  display: flex;
  justify-content: space-between;
}
.flex-s {
  display: flex;
}
.flex-hi {
  display: inline-flex;
  align-items: center;
}
.flex-hz {
  display: flex;
  align-items: center;
}
.flex-hz-c {
  display: flex;
  align-items: center;
  justify-content: center;
}
.m-c {
  margin-left: auto;
  margin-right: auto;
}
.m-5 {
  margin: 5px !important;
}
.a-c {
  text-align: center;
}
.ac {
  text-align: center;
}
.f-1em {
  font-size: 1rem !important;
}
.mr-6 {
  margin-right: 6px !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.mr-20 {
  margin-right: 20px !important;
}
.t-a-r {
  text-align: right;
}
.t-a-s {
  text-align: start;
}
.w-a-90 {
  width: 90px !important;
  min-width: 90px !important;
  max-width: 90px !important;
}
.fs-11 {
  font-size: 11px !important;
}
.employee-card .ps__rail-x,
.ps__rail-y {
  opacity: 0.6;
}
.-mt-2-rem {
  margin-top: -2rem;
}
.mt-17 {
  margin-top: 17px;
}
.p-8 {
  padding: 8px;
}
.h-22 {
  height: 22px !important;
}
.m-0 {
  margin: 0px;
}
.ml-15 {
  margin-left: 15px !important;
}
.all-scroll {
  cursor: all-scroll;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.ml-3 {
  margin-left: 3px !important;
}
.ml-5 {
  margin-left: 5px !important;
}
.mt-5 {
  margin-top: 5px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-0 {
  margin-top: 0px !important;
}
.mt-1 {
  margin-top: 1px !important;
}
.mt-15 {
  margin-top: 15px !important;
}
.cursor {
  cursor: pointer;
}
.grey {
  color: grey;
}
.grey .MuiStepLabel-label {
  color: grey !important;
}
.MuiTypography-root.MuiTypography-h3.MuiTypography-colorTextPrimary {
  margin-top: 10px;
}
.p-8-3 {
  padding: 8px 3px;
}
.pl-0 {
  padding-left: 0px !important;
}
.pr-0 {
  padding-right: 0px !important;
}
.pr-16 {
  padding-right: 16px !important;
}
.ml-5 {
  margin-left: 5px;
}
.ml-10 {
  margin-left: 10px !important;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.employee-details th {
  width: 130px;
}
.employee-details td {
  width: 150px;
}
.login-view #tsparticles {
  height: 100vh;
}
.employee-grid-view #tsparticles {
  position: absolute;
  width: 100%;
  height: 100%;
}
.justify-content-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.flex {
  display: flex;
  align-items: center;
}
.disabled-gray {
  pointer-events: none;
  opacity: 0.5;
}

.disabled-80 {
  pointer-events: none;
  opacity: 0.8;
}

.review-table td {
  max-width: 260px;
}

.cp-label span {
  cursor: pointer !important;
}

.error {
  color: red;
}

.employee-container {
  width: 100%;
  /* height: 250px; */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.employee-image {
  /* flex-shrink: 0; */
  /* -webkit-flex-shrink: 0; */
  width: 100%;
  /* height: 250px; */
  /* object-fit: cover; */
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}

.show-on-hover {
  display: none;
}

.show-on-hover:hover {
  display: block !important;
}

.transparent-hover:hover {
  background-color: transparent !important;
}

.opacity-hover-70:hover {
  opacity: 0.7;
}

.gray-hover:hover {
  color: gray;
  font-weight: bold;
  text-shadow: 0 0 #d09090;
}

.white-hover:hover {
  color: #fff;
  text-shadow: 0 0 #fff;
}

.bg-gray-hover:hover {
  background: gray;
}

.bg-white-hover:hover {
  background: #fff;
}

.documents-list .MuiTypography-displayBlock {
  overflow: hidden !important;
  text-overflow: ellipsis;
  width: 90%;
}

.border-error {
  border: 1px solid red;
}

.hidden {
  display: none;
}

.w-full {
  width: 100%;
}

.tox.tox-tinymce.tox-fullscreen {
  z-index: 99999 !important;
}
.tox-fullscreen {
  z-index: 99999 !important;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.z-100 {
  z-index: 100;
}

.z-1000 {
  z-index: 1000;
}

.fr {
  float: right;
}

.solid-border {
  border-top: 10px solid teal;
  border-radius: 10px;
}

.op-50-hover:hover {
  opacity: 0.5;
}

.t-u {
  text-transform: capitalize;
}

.b-n {
  border: 0px !important;
}

.review-actions-btn {
  max-width: 260px;
}

.embeded-vimeo {
  padding: 0rem 1.5rem;
}

.v-ac {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.h-ac {
  position: absolute;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

@media screen and (max-width: 800px) {
  .embeded-vimeo {
    padding: 0rem;
    margin-bottom: 1rem;
  }
}

@media screen and (min-width: 1025px) {
  .show-on-mobile {
    display: none !important;
  }
}

@media screen and (max-width: 1024px) {
  .show-on-desktop {
    display: none !important;
  }
}

@media screen and (min-width: 520px) {
  .alert-dialog canvas {
    width: 320px;
    height: 320px;
    border: 1px solid rgba(255, 255, 255, 0.12);
  }

  .sm-flex-h {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .sm-flex-end {
    display: flex;
    justify-content: flex-end;
  }

  .sm-ml-2 {
    margin-left: 0.5rem !important;
  }

  .sm-fr {
    float: right;
  }
}

@media screen and (max-width: 520px) {
  .alert-dialog canvas {
    width: 258px;
    height: 260px;
    border: 1px solid rgba(255, 255, 255, 0.12);
  }
  .mobile-mt-2 {
    margin-top: 0.5rem !important;
  }
}

.ps > .ps__rail-x,
.ps > .ps__rail-y {
  opacity: 0.6;
}
.sortable-chosen {
  transform: translate(0, 0) !important;
  opacity: 0.9999 !important;
  overflow: hidden;
  position: relative;
}
